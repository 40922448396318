import React, { useEffect } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import '../singlePage.scss'
import { deleteAllCookies, useOptOut } from "../../utils/analyticsDoNotTrack"


const NotFoundPage = () => {
  useEffect(() => {
    deleteAllCookies()
    useOptOut()
  });
  return (<Layout>
    <SEO title="404: Not found" />
    <div className="single-page">
      <div className="flex-wrapper">
        <article>
          <h3>NOT FOUND</h3>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </article>
      </div>
    </div>
  </Layout>
)}

export default NotFoundPage
